html {
    overflow-y: auto;
    body {
        margin: 0;
        font:
            14px/1.5 "Microsoft YaHei",
            "PingFang SC",
            "Helvetica Neue",
            Helvetica,
            Arial,
            "Hiragino Sans GB",
            "Heiti SC",
            "WenQuanYi Micro Hei",
            sans-serif;
        color: #333;
        a {
            text-decoration: none;
            color: #333;
        }
        @keyframes background-big {
            0% {
                background-size: 100% 100%;
            }

            100% {
                background-size: 110% 110%;
            }
        }
    }
}
